import axios from '../instance'
import { IBodyDataCreateBooking } from './type'
export const getOrderList = async (params: any) => {
	try {
		const response = await axios.get('/booking', { params: {...params} })
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const bookingCreation = async (bodyData: IBodyDataCreateBooking) => {
	try {
		const { data } = await axios.post('/booking/create', bodyData)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteBookingById = async (bookingId: string) => {
	try {
		const response = await axios.put(`/booking/cancel/${bookingId}`)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const bookingUpdate = async (bookingId: string, bodyData: IBodyDataCreateBooking) => {
	try {
		const response = await axios.put(`/booking/edit/${bookingId}`, bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}