import dayjs from 'dayjs'
import 'dayjs/locale/th'
export const generateDateRangeBooking = () => {
	const dateList = []
	let customTime = {
		label:dayjs().format('DD-MM-YYYY'),
		value:dayjs().format('YYYY-MM-DD'),
	}
	const isAfter = dayjs().diff(dayjs().startOf('d'), 'h') > 16
	if (isAfter) {
		customTime = {
			label:dayjs()
				.add(1, 'days')
				.format('DD-MM-YYYY'),
			value:dayjs()
				.add(1, 'days')
				.format('YYYY-MM-DD')
		}
	}
	dateList.push(customTime)
	let nextDay = 6
	if (dayjs(customTime.value).isSame(dayjs(), 'day')) {
		nextDay = 7

	}
	for (let index = 1; index < nextDay; index++) {
		const nextTime = {
			label:dayjs(customTime.value)
				.add(index, 'days')
				.format('DD-MM-YYYY'),
			value:dayjs(customTime.value)
				.add(index, 'days')
				.format('YYYY-MM-DD')
		}
		dateList.push(nextTime)
	}
	return dateList
}
export const generateTimeRangeBooking = (date:string) => {
	const timeList = []
	if (dayjs(date).isSame(dayjs(),'day')) {
		const isBeforeTen = dayjs().diff(dayjs().startOf('d'), 'h') < 10
		if (isBeforeTen) {
			const time = {
				label:'08:01~10:00',
				value:dayjs()
					.set('hour', 8)
					.set('minute', 0)
					.set('second', 0)
					.format('HH:mm:ss')
			}
			if (dayjs().diff(dayjs().startOf('d'), 'h') >= 8) {
				time.value = dayjs().add(2,'m')
					.format('HH:mm:ss')
			}
			timeList.push(time)
		}
		const isBefore = dayjs().diff(dayjs().startOf('d'), 'h') < 12
		if (isBefore) {
			const time = {
				label:'10:01~12:00',
				value:dayjs()
					.set('hour', 10)
					.set('minute', 0)
					.set('second', 0)
					.format('HH:mm:ss')
			}
			if (dayjs().diff(dayjs().startOf('d'), 'h') >= 10) {
				time.value = dayjs().add(2,'m')
					.format('HH:mm:ss')
			}
			timeList.push(time)
		}
		const isBeforeFifteen = dayjs().diff(dayjs().startOf('d'), 'h') < 15
		if (isBeforeFifteen) {
			const time = {
				label:'13:01~15:00',
				value:dayjs()
					.set('hour', 13)
					.set('minute', 0)
					.set('second', 0)
					.format('HH:mm:ss')
			}
			if (dayjs().diff(dayjs().startOf('d'), 'h') >= 13) {
				time.value = dayjs().add(2,'m')
					.format('HH:mm:ss')
			}
			timeList.push(time)
		}
		const isBeforeEighteen = dayjs().diff(dayjs().startOf('d'), 'h') < 18
		if (isBeforeEighteen) {
			const time = {
				label:'16:01~18:00',
				value:dayjs()
					.set('hour', 16)
					.set('minute', 0)
					.set('second', 0)
					.format('HH:mm:ss')
			}
			if (dayjs().diff(dayjs().startOf('d'), 'h') >= 16) {
				time.value = dayjs().add(2,'m')
					.format('HH:mm:ss')
			}
			timeList.push(time)
		}
	}else{
		const timeRange1 = {
			label:'08:01~10:00',
			value:dayjs()
				.set('hour', 8)
				.set('minute', 0)
				.set('second', 0)
				.format('HH:mm:ss')
		}
		const timeRange2 = {
			label:'10:01~12:00',
			value:dayjs()
				.set('hour', 10)
				.set('minute', 0)
				.set('second', 0)
				.format('HH:mm:ss')
		}
		timeList.push(timeRange1)
		timeList.push(timeRange2)
		const timeRange3 = {
			label:'13:01~15:00',
			value:dayjs()
				.set('hour', 13)
				.set('minute', 0)
				.set('second', 0)
				.format('HH:mm:ss')
		}
		const timeRange4 = {
			label:'16:01~18:00',
			value:dayjs()
				.set('hour', 16)
				.set('minute', 0)
				.set('second', 0)
				.format('HH:mm:ss')
		}
		timeList.push(timeRange3)
		timeList.push(timeRange4)
	}
	return timeList
}
